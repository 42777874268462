import React, { ReactElement, ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import websiteOgImageJPEG from "../assets/img/bindays-og.jpg";

const APP_HOSTED_DOMAIN = "bindayfinder.azurewebsites.net";

interface SeoHelmetProps {
  title: string;
  description: string;
  children?: ReactNode;
}

export default function SeoHelmet({
  title = "Street Sweeping - City of Canterbury Bankstown",
  description,
  children,
}: SeoHelmetProps): ReactElement {
  const { pathname } = useLocation();
  const rootUrl = `https://${APP_HOSTED_DOMAIN}${pathname}`;
  const websiteOgImageSrc = `https://${APP_HOSTED_DOMAIN}${websiteOgImageJPEG}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description || title} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={rootUrl} />
      <meta property="og:site_name" content="Canterbury‑Bankstown" />
      <meta property="og:image" content={websiteOgImageSrc} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="twitter:image" content={websiteOgImageSrc} />
      <link rel="canonical" href={rootUrl} />
      {children}
    </Helmet>
  );
}
