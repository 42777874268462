import React, { ReactElement } from "react";

import wastelessGenerationPNG from "../assets/img/wasteless-gen.png";
import wastelessGenerationWEBP from "../assets/img/wasteless-gen.webp";
import wastelessIconSVG from "../assets/img/svg/graphic/wasteless.svg";

export default function WastelessGenerationBlock(): ReactElement {
  return (
    <section className="wasteless-gen">
      <div className="wasteless-gen__container container">
        <div className="wasteless-gen__box">
          <div className="wasteless-gen__photo">
            <div className="wasteless-gen__photo-inner">
              <picture>
                <source srcSet={wastelessGenerationWEBP} type="image/webp" />
                <img
                  src={wastelessGenerationPNG}
                  alt="Wasteless generation"
                  className="wasteless-gen__photo-img"
                />
              </picture>
            </div>
          </div>
          <div className="wasteless-gen__descr">
            <div className="wasteless-gen__icon">
              <img
                src={wastelessIconSVG}
                alt="Wasteless Icon"
                className="wasteless-gen__icon-img"
              />
            </div>
            <h2 className="wasteless-gen__title h2">
              Welcome to the&nbsp;waste
              <span className="color-orange">less</span> generation.
            </h2>
            <div className="wasteless-gen__text subtitle">
              For more information about Canterbury-Bankstown's residential
              waste and recycling services
            </div>
            <div className="wasteless-gen__actions">
              <a
                href="https://www.cbcity.nsw.gov.au/resident/waste-recycling"
                className="wasteless-gen__actions-btn button button_shadow_default"
                target="_blank"
                rel="noreferrer"
              >
                Visit our page here
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
