import React, { ReactElement, useMemo } from "react";
import { BinSchedule, BinType } from "../models";
import BinCard from "./BinCard";

interface BinCardGroupProps {
  binSchedule: BinSchedule;
  showGreenBinDisclaimer: boolean;
}

export default function BinCardGroup({
  binSchedule,
  showGreenBinDisclaimer,
}: BinCardGroupProps): ReactElement {
  const {
    redBinDate,
    redBinDate2,
    redBinDate3,
    redBinDate4,
    yellowBinDate,
    yellowBinDate2,
    yellowBinDate3,
    greenBinDate,
  } = binSchedule;

  const getDateForDisplay = (
    date1: Date | null,
    date2?: Date | null
  ): Date | null => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (date1 && date2 && today > date1) {
      return date2;
    }
    return date1;
  };

  const yellowBinDateForDisplay = getDateForDisplay(
    yellowBinDate,
    yellowBinDate2
  );

  const isYellowBinCollectedEarlier = useMemo(() => {
    if (yellowBinDateForDisplay && greenBinDate) {
      return yellowBinDateForDisplay < greenBinDate;
    }
    return false;
  }, [yellowBinDateForDisplay, greenBinDate]);

  const RedBinCard = (): ReactElement => (
    <BinCard
      binType={BinType.red}
      binDates={[redBinDate, redBinDate2, redBinDate3, redBinDate4]}
      title={"Your next red bin collection is"}
    />
  );

  const YellowBinCard = (): ReactElement => (
    <BinCard
      binType={BinType.yellow}
      binDates={[yellowBinDate, yellowBinDate2, yellowBinDate3]}
      title={"Your next yellow bin collection is"}
    />
  );

  const GreenBinCard = (): ReactElement => (
    <BinCard
      binType={BinType.green}
      binDates={[greenBinDate]}
      title={"Your next green bin collection is"}
      showGreenBinDisclaimer={showGreenBinDisclaimer}
    />
  );

  return (
    <>
      <RedBinCard />
      {isYellowBinCollectedEarlier ? (
        <>
          <YellowBinCard />
          <GreenBinCard />
        </>
      ) : (
        <>
          <GreenBinCard />
          <YellowBinCard />
        </>
      )}
    </>
  );
}
