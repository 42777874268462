export enum BinServiceDay {
  monday = "Monday",
  tuesday = "Tuesday",
  wednesday = "Wednesday",
  thursday = "Thursday",
  friday = "Friday",
}

export enum Weekend {
  saturday = "Saturday",
  sunday = "Sunday",
}

export type WeekDay = BinServiceDay | Weekend;

export enum BinCollectionZone {
  zoneA = "A",
  zoneB = "B",
  weekly = "Weekly",
}

export enum BinType {
  red = "red",
  yellow = "yellow",
  green = "green",
}

export enum ExcelTabType {
  one = "1",
  twoA = "2a",
  twoB = "2b",
  twoC = "2c",
  three = "3",
  four = "4",
  five = "5",
  six = "6",
}

export interface BinDay {
  fullAddress: string;
  address: string;
  street: string;
  tab: ExcelTabType;
  redServiceDay: BinServiceDay;
  redServiceDay2: BinServiceDay;
  redServiceDay3: BinServiceDay;
  redServiceDay4: BinServiceDay;
  yellowServiceDay: BinServiceDay;
  greenServiceDay: BinServiceDay;
  zone: BinCollectionZone;
  yellowZone: BinCollectionZone | null;
}

export interface BinSchedule {
  redBinDate: Date | null;
  redBinDate2: Date | null;
  redBinDate3: Date | null;
  redBinDate4: Date | null;
  yellowBinDate: Date | null;
  yellowBinDate2: Date | null;
  yellowBinDate3: Date | null;
  greenBinDate: Date | null;
}
