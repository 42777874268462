import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { BinDay } from "../models";
import pako from "pako";

interface BinDayDatasetContextProps {
  binDayDataset: Array<BinDay>;
  isLoaded: boolean;
}

export const BinDayDatasetContext = createContext<BinDayDatasetContextProps>({
  binDayDataset: [],
  isLoaded: false,
});

export const BinDayDatasetProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [binDayDataset, setBinDayDataset] = useState<BinDay[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch("./streetData.json.gz");
        const compressedStreetData = await response.arrayBuffer();
        const streetData = JSON.parse(
          pako.inflate(compressedStreetData, { to: "string" })
        );
        setBinDayDataset(streetData);
        setIsLoaded(true);
      } catch (error) {
        console.log("Error Reading data: ", error);
        setBinDayDataset([]);
      }
    })();
  }, []);

  return (
    <BinDayDatasetContext.Provider value={{ isLoaded, binDayDataset }}>
      {children}
    </BinDayDatasetContext.Provider>
  );
};

export const useBinDayDataset = (): BinDayDatasetContextProps => {
  const dataset = useContext(BinDayDatasetContext);
  if (dataset === undefined) {
    throw new Error("No dataset context");
  }
  return dataset;
};
