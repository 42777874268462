import React, { CSSProperties, ReactElement, useCallback, useRef } from "react";

import { SpriteIcon } from "../graphics";
import shareIconSVG from "../../assets/img/svg/graphic/share.svg";
import { useDeviceDetect, useModalToggler } from "../../hooks";

interface ShareModalProps {
  address: string;
  isModalOpen: boolean;
  onModalClose: () => void;
}

enum ShareChannel {
  facebook,
  twitter,
  email,
  sms,
}

export default function ShareModal({
  address,
  isModalOpen,
  onModalClose,
}: ShareModalProps): ReactElement {
  const modalRef = useModalToggler(isModalOpen);
  const isMobile = useDeviceDetect();

  const copyButtonRef = useRef<HTMLButtonElement>(null);
  const copyToClipBoard = useCallback(async () => {
    if (copyButtonRef.current) {
      try {
        await navigator.clipboard.writeText(window.location.href);
        copyButtonRef.current?.classList.add("show-status");
        setTimeout(() => {
          copyButtonRef.current?.classList.remove("show-status");
        }, 2000);
      } catch (err) {
        console.log(err);
      }
    }
  }, [copyButtonRef]);

  const getShareUrl = (channel: ShareChannel) => {
    const encodedShareUrl = encodeURIComponent(window.location.href);
    const title = `Bin Days for ${address}`;

    switch (channel) {
      case ShareChannel.facebook:
        return `https://www.facebook.com/sharer/sharer.php?u=${encodedShareUrl}&p[title]=${title}`;
      case ShareChannel.twitter:
        return `https://twitter.com/share?url=${encodedShareUrl}&ref_src=twsrc&text=${title}`;
      case ShareChannel.email:
        return `mailto:?subject=${title}&body=${encodedShareUrl}`;
      case ShareChannel.sms:
        return `sms:?&body=${title}. ${encodedShareUrl}`;
      default:
        return "";
    }
  };

  return (
    <div ref={modalRef} className="share-modal modal js-modal">
      <div className="modal__box">
        <div className="modal__content default-modal share-modal__main">
          <button
            className="default-modal__close js-modal-close"
            type="button"
            onClick={onModalClose}
          >
            <SpriteIcon iconName="x" className="default-modal__close-icon" />
          </button>
          <div className="share-modal__box default-modal__box align-center">
            <div className="default-modal__icon">
              <img
                src={shareIconSVG}
                alt="Share Icon"
                className="default-modal__icon-img"
              />
            </div>
            <h3 className="default-modal__title h4">
              Share this important information
            </h3>
            <div className="default-modal__text">
              Share this information so your family, friends and/or flatmates
              know their bin day
            </div>
            <div className="share-modal__share">
              <ul className="share-modal__share-list share-list">
                <li className="share-list__item">
                  <a
                    href={getShareUrl(ShareChannel.email)}
                    className="share-list__link"
                    target="_blank"
                    rel="noreferrer"
                    style={{ "--bg": "#E61F14" } as CSSProperties}
                  >
                    <SpriteIcon iconName="email" className="share-list__icon" />
                  </a>
                </li>
                <li className="share-list__item">
                  <a
                    href={getShareUrl(ShareChannel.facebook)}
                    className="share-list__link"
                    target="_blank"
                    rel="noreferrer"
                    style={{ "--bg": "#0676E4" } as CSSProperties}
                  >
                    <SpriteIcon iconName="fb2" className="share-list__icon" />
                  </a>
                </li>
                {isMobile && (
                  <li className="share-list__item">
                    <a
                      href={getShareUrl(ShareChannel.sms)}
                      className="share-list__link"
                      target="_blank"
                      rel="noreferrer"
                      style={{ "--bg": "#000000" } as CSSProperties}
                    >
                      <SpriteIcon iconName="sms" className="share-list__icon" />
                    </a>
                  </li>
                )}
                <li className="share-list__item">
                  <a
                    href={getShareUrl(ShareChannel.twitter)}
                    className="share-list__link"
                    target="_blank"
                    rel="noreferrer"
                    style={{ "--bg": "#1E9CEB" } as CSSProperties}
                  >
                    <SpriteIcon
                      iconName="twitter2"
                      className="share-list__icon"
                    />
                  </a>
                </li>
              </ul>
              <button
                ref={copyButtonRef}
                className="share-modal__share-copy copy-btn js-copy-btn"
                onClick={copyToClipBoard}
              >
                <span className="copy-btn__content">
                  Or <strong>copy link</strong> to share
                </span>
                <span className="copy-btn__status">Link copied</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
