import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import logoPNG from "../assets/img/logo.png";
import { SpriteIcon } from "./graphics";

export default function Header(): ReactElement {
  return (
    <div className="main-header-outer js-main-header-outer">
      <header className="main-header js-main-header">
        <div className="main-header__container container">
          <div className="main-header__content">
            <div className="main-header__logo logo">
              <Link to="/" className="logo__link">
                <img
                  src={logoPNG}
                  className="logo__img"
                  alt="Canterbury Bankstown logo"
                />
              </Link>
            </div>
            <ul className="main-header__social social">
              <li className="social__item">
                <a
                  href="https://www.facebook.com/ourcbcity"
                  className="social__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SpriteIcon iconName="fb" className="social__icon" />
                </a>
              </li>
              <li className="social__item">
                <a
                  href="https://twitter.com/ourcbcity"
                  className="social__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SpriteIcon iconName="twitter" className="social__icon" />
                </a>
              </li>
              <li className="social__item">
                <a
                  href="https://www.instagram.com/ourcbcity/"
                  className="social__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SpriteIcon iconName="instagram" className="social__icon" />
                </a>
              </li>
              <li className="social__item">
                <a
                  href="https://www.youtube.com/channel/UCFDSymRVxUSM4uxvUan8nfg"
                  className="social__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SpriteIcon iconName="youtube" className="social__icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
}
