import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";

import Skeleton from "react-loading-skeleton";
import { SpriteIcon } from "../components/graphics";
import { ShareModal } from "./modals";

interface ResultPageHeaderProps {
  address: string;
}

export default function ResultPageHeader({
  address,
}: ResultPageHeaderProps): ReactElement {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  return (
    <header className="page-header">
      <div className="page-header__container container">
        <div className="page-header__back back">
          <Link to="/" className="back__link">
            <SpriteIcon iconName="arrow-left" className="back__link-icon" />
            <span className="back__link-text">Bin days for:</span>
          </Link>
        </div>
        {address.length > 0 ? (
          <div className="page-header__box">
            <h1 className="page-header__title h3">{address}</h1>

            <aside className="page-header__aside">
              <button
                className="page-header__aside-btn button button_type_ghost js-modal-opener"
                data-modal-id="share"
                type="button"
                onClick={() => setIsShareModalOpen(true)}
              >
                <span className="button__content">
                  <span className="button__icon">
                    <SpriteIcon iconName="share" className="button__icon-img" />
                  </span>
                  <span className="button__text">Share your bin days</span>
                </span>
              </button>
            </aside>
          </div>
        ) : (
          <div>
            <Skeleton
              style={{ paddingTop: "10rem", marginTop: "10rem" }}
              count={2}
            />
          </div>
        )}
      </div>
      <div className="modals">
        <ShareModal
          address={address}
          isModalOpen={isShareModalOpen}
          onModalClose={() => setIsShareModalOpen(false)}
        />
      </div>
    </header>
  );
}
