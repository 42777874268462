import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";

import { useScrollToTop } from "../hooks";
import SeoHelmet from "../components/SeoHelmet";
import { BinDayCalendarModal, WastelessGenerationBlock } from "../components";
import { SpriteIcon } from "../components/graphics";

import binDayCalendarIconSVG from "../assets/img/info-icons/bin-day-calendars.svg";
import extraBinInfoIconSVG from "../assets/img/info-icons/extra-bin.svg";
import questionIconSVG from "../assets/img/info-icons/question.svg";
import damagedBinsIconSVG from "../assets/img/info-icons/placement-responsibilities.svg";
import whichGoesWhereIconSVG from "../assets/img/info-icons/which-rubbish.svg";

export default function AboutPage(): ReactElement {
  useScrollToTop();

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  return (
    <div>
      <SeoHelmet
        title="Bin Day Finder - City of Canterbury Bankstown"
        description="Find out what days your red, yellow and green bins are collected and download your bin day calendar."
      />
      <main className="main">
        {/* Page Header */}
        <header className="page-header">
          <div className="page-header__container container">
            <div className="page-header__box">
              <h1 className="page-header__title h3">Bin Day Finder</h1>
              <aside className="page-header__aside">
                <Link
                  to="/"
                  className="page-header__aside-btn button button_type_ghost"
                >
                  <span className="button__content">
                    <span className="button__text">Find your Bin Days</span>
                    <span className="button__icon">
                      <SpriteIcon
                        iconName="arrow-circle-right"
                        className="button__icon-img"
                      />
                    </span>
                  </span>
                </Link>
              </aside>
            </div>
          </div>
        </header>
        {/* /Page Header */}
        {/* Extra Links Cards */}
        <section className="extra-links section bg-gray">
          <div className="extra-links__container container">
            <h2 className="extra-links__title h2">
              <span className="color-orange">
                Follow these links for more information
              </span>{" "}
              about your waste, recycling, Bulky Waste Clean Up and other
              services …
            </h2>
            <div className="extra-links__cards link-cards-set row">
              {/* Link Card */}
              <div className="link-cards-set__col col-xl-4 col-sm-6">
                <a
                  href="https://www.cbcity.nsw.gov.au/council/forms/waste-recycling"
                  className="link-cards-set__item link-card"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="link-card__icon">
                    <img
                      src={extraBinInfoIconSVG}
                      alt="Extra Bin Icon"
                      className="link-card__icon-img"
                    />
                  </div>
                  <div className="link-card__text">Apply for an extra bin</div>
                </a>
              </div>
              {/* /Link Card */}
              {/* Link Card */}
              <div className="link-cards-set__col col-xl-4 col-sm-6">
                <a
                  href="https://reportit.cbcity.nsw.gov.au/"
                  className="link-cards-set__item link-card"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="link-card__icon">
                    <img
                      src={questionIconSVG}
                      alt="Damaged or missing bins Icon"
                      className="link-card__icon-img"
                    />
                  </div>
                  <div className="link-card__text">
                    Report a damaged or missing bin
                  </div>
                </a>
              </div>
              {/* /Link Card */}
              {/* Link Card */}
              <div className="link-cards-set__col col-xl-4 col-sm-6">
                <a
                  href="https://www.cbcity.nsw.gov.au/resident/waste-recycling/household-bin-collection#4"
                  className="link-cards-set__item link-card"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="link-card__icon">
                    <img
                      src={damagedBinsIconSVG}
                      alt="Damaged or missing bins Icon"
                      className="link-card__icon-img"
                    />
                  </div>
                  <div className="link-card__text">Put your bins out right</div>
                </a>
              </div>
              {/* /Link Card */}
              {/* Link Card */}
              <div className="link-cards-set__col col-xl-4 col-sm-6">
                <a
                  href="https://www.cbcity.nsw.gov.au/resident/waste-recycling/sorting-your-waste"
                  className="link-cards-set__item link-card"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="link-card__icon">
                    <img
                      src={whichGoesWhereIconSVG}
                      alt="Which rubbish goes where Icon"
                      className="link-card__icon-img"
                    />
                  </div>
                  <div className="link-card__text">Sorting your waste</div>
                </a>
              </div>
              {/* /Link Card */}
              {/* Link Card */}
              <div className="link-cards-set__col col-xl-4 col-sm-6">
                <a
                  href="https://reportit.cbcity.nsw.gov.au/"
                  className="link-cards-set__item link-card"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="link-card__icon">
                    <img
                      src={questionIconSVG}
                      alt="Missed or unemptied bin Icon"
                      className="link-card__icon-img"
                    />
                  </div>
                  <div className="link-card__text">
                    Report a missed collection
                  </div>
                </a>
              </div>
              {/* /Link Card */}
              {/* Link Card */}
              <div className="link-cards-set__col col-xl-4 col-sm-6">
                <a
                  href="https://www.cbcity.nsw.gov.au/resident/waste-recycling/upcoming-changes-to-waste-services"
                  className="link-cards-set__item link-card"
                  style={{ width: "100%" }}
                >
                  <div className="link-card__icon">
                    <img
                      src={binDayCalendarIconSVG}
                      alt="Bin day calendars Icon"
                      className="link-card__icon-img"
                    />
                  </div>
                  <div className="link-card__text">
                    Join the Wasteless Generation
                  </div>
                </a>
              </div>
              {/* /Link Card */}
            </div>
          </div>
        </section>
        {/* /Extra Links Cards */}

        <WastelessGenerationBlock />
      </main>
      <BinDayCalendarModal
        isModalOpen={isShareModalOpen}
        onModalClose={() => setIsShareModalOpen(false)}
      />
    </div>
  );
}
