import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BinDay } from "../models";
import { BinDayDatasetContext } from "../contexts";

interface ResultPageLocationState {
  lookupAddress: BinDay;
}

export default function useBinDayFromAddress() {
  const {
    state: locationState,
    search,
  } = useLocation<ResultPageLocationState>();

  const { binDayDataset } = useContext(BinDayDatasetContext);

  const getBinDayByAddress = useCallback(
    (fullAddress: string) => {
      return binDayDataset.find((item) => item.fullAddress === fullAddress);
    },
    [binDayDataset]
  );

  const queryParams = new URLSearchParams(search);
  const [resultAddress, setResultAddress] = useState<BinDay>();

  useEffect(() => {
    if (locationState) {
      setResultAddress(locationState.lookupAddress);
    }
  }, [locationState]);

  const queryAddress = queryParams.get("address");
  useEffect(() => {
    if (queryAddress && !resultAddress) {
      setResultAddress(getBinDayByAddress(queryAddress));
    }
  }, [queryAddress, resultAddress, getBinDayByAddress]);

  return resultAddress;
}
