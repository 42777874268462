import { BinServiceDay, WeekDay, Weekend } from "../models";

export default function getBinTakeoutDay(serviceDay: BinServiceDay): WeekDay {
  switch (serviceDay) {
    case BinServiceDay.tuesday:
      return BinServiceDay.monday;
    case BinServiceDay.wednesday:
      return BinServiceDay.tuesday;
    case BinServiceDay.thursday:
      return BinServiceDay.wednesday;
    case BinServiceDay.friday:
      return BinServiceDay.thursday;
    default:
      return Weekend.sunday;
  }
}
