import React, { ReactElement } from "react";

import { SpriteIcon } from "../graphics";
import calendarIconSVG from "../../assets/img/svg/graphic/calendar.svg";
import { useModalToggler } from "../../hooks";
import AddressSearchBox from "../AddressSearchBox";

interface BinDayCalendarModalProps {
  isModalOpen: boolean;
  onModalClose: () => void;
}

export default function BinDayCalendarModal({
  isModalOpen,
  onModalClose,
}: BinDayCalendarModalProps): ReactElement {
  const modalRef = useModalToggler(isModalOpen);

  return (
    <div ref={modalRef} className="calendar-modal modal js-modal">
      <div className="modal__box">
        <div className="modal__content default-modal calendar-modal__main">
          <button
            className="default-modal__close js-modal-close"
            type="button"
            onClick={onModalClose}
          >
            <SpriteIcon iconName="x" className="default-modal__close-icon" />
          </button>
          <div className="calendar-modal__box default-modal__box align-center">
            <div className="default-modal__icon">
              <img
                src={calendarIconSVG}
                alt="Calendar Icon"
                className="default-modal__icon-img"
              />
            </div>
            <h3 className="default-modal__title h4">Bin day calendar</h3>
            <div className="default-modal__text">
              Enter your street address below to download your printable bin day
              calendar.
            </div>
            <AddressSearchBox formClassName="default-modal__search" />
            <div className="calendar-modal__hidden js-calendar-modal__hidden">
              <div className="calendar-modal__download">
                <a
                  href="http://lorem.com"
                  className="calendar-modal__download-btn button button_size_large button_shadow_default"
                  download
                >
                  <span className="button__content">
                    <span className="button__text">Download your calendar</span>
                    <span className="button__icon">
                      <SpriteIcon
                        iconName="download"
                        className="button__icon-img"
                      />
                    </span>
                  </span>
                </a>
              </div>
              <div className="calendar-modal__share">
                Or
                <button
                  className="calendar-modal__share-btn js-modal-opener"
                  data-modal-id="share"
                >
                  share it
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
