import React, { ReactElement } from "react";
import { BinDay, BinServiceDay } from "../models";
import { SpriteIcon } from "./graphics";

interface DownloadCalendarBlockProps {
  binDay?: BinDay;
}

const binServiceDayPdfKey = {
  [BinServiceDay.monday]: "MON",
  [BinServiceDay.tuesday]: "TUES",
  [BinServiceDay.wednesday]: "WED",
  [BinServiceDay.thursday]: "THUS",
  [BinServiceDay.friday]: "FRI",
};

export default function DownloadCalendarBlock({
  binDay,
}: DownloadCalendarBlockProps): ReactElement {
  return (
    <div className="bin-days__download">
      <a
        href={
          binDay
            ? `/pdfs/2024_Waste_Calendar_${
                binServiceDayPdfKey[binDay.redServiceDay]
              }_${binDay.zone}_FA01.pdf`
            : ""
        }
        className="bin-days__download-btn button button_size_large button_shadow_default"
      >
        <span className="button__content">
          <span className="button__text">Download your calendar</span>
          <span className="button__icon">
            <SpriteIcon iconName="download" className="button__icon-img" />
          </span>
        </span>
      </a>
    </div>
  );
}
