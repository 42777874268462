import React, { ReactElement } from "react";
import logoPNG from "../assets/img/logo.png";

export default function Footer(): ReactElement {
  return (
    <footer className="main-footer">
      <div className="main-footer__container container">
        <div className="main-footer__content">
          <div className="main-footer__logo logo">
            <a href="/" className="logo__link">
              <img
                src={logoPNG}
                className="logo__img"
                alt="Canterbury Bankstown logo"
              />
            </a>
          </div>
          <nav className="main-footer__menu main-menu">
            <ul className="main-menu__list">
              <li className="main-menu__item">
                <a
                  href="https://cb.city/privacy"
                  className="main-menu__link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy statement
                </a>
              </li>
              <li className="main-menu__item">
                <a
                  href="https://cb.city/accessibility"
                  className="main-menu__link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Accessibility
                </a>
              </li>
              <li className="main-menu__item">
                <a
                  href="https://cb.city/contactus"
                  className="main-menu__link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
}
