// const getSuffixedNumber = (num: number) => {
//   if (num > 3 && num < 21) return `${num}th`;
//   switch (num % 10) {
//     case 1:
//       return `${num}st`;
//     case 2:
//       return `${num}nd`;
//     case 3:
//       return `${num}rd`;
//     default:
//       return `${num}th`;
//   }
// };

/* Returns formatted date string. Ex: 12th March */
export default function formatDisplayDate(date: Date): string {
  return date.toLocaleDateString("en-AU", {
    weekday: "short",
    month: "long",
    day: "numeric",
  });
}
