import React, { ReactElement } from "react";
import spriteSVG from "../assets/img/svg/sprite.svg";

export const SpriteIcon = ({
  iconName,
  className,
}: {
  className?: string;
  iconName?: string;
}): ReactElement => (
  <svg className={`sprite-icon ${className}`}>
    <use xlinkHref={`${spriteSVG}#${iconName}`} />
  </svg>
);
