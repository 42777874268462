import React, { ReactElement, useMemo } from "react";
import { useBinDayFromAddress, useScrollToTop } from "../hooks";
import SeoHelmet from "../components/SeoHelmet";
import {
  BinTipsBlock,
  DownloadCalendarBlock,
  ResultPageHeader,
  WastelessGenerationBlock,
  SharedBinAlertBanner,
} from "../components";
import calendarIconSVG from "../assets/img/svg/graphic/calendar.svg";
import BinCardGroup from "../components/BinCardGroup";
import { getBinServiceSchedule, getBinTakeoutDay } from "../utils";
import { ExcelTabType } from "../models";

export default function ResultPage(): ReactElement {
  useScrollToTop();

  const binDay = useBinDayFromAddress();
  const binSchedule = getBinServiceSchedule(binDay);

  const showBanner = useMemo(
    () =>
      binDay &&
      [
        ExcelTabType.twoA,
        ExcelTabType.twoB,
        ExcelTabType.twoC,
        ExcelTabType.four,
        ExcelTabType.five,
      ].includes(binDay.tab),
    [binDay]
  );

  const showGreenBinDisclaimer = showBanner || false;

  const showDownloadButton = useMemo(() => binDay?.tab === ExcelTabType.one, [
    binDay,
  ]);

  const CalendarCardContent = ({
    excelTab,
  }: {
    excelTab: ExcelTabType;
  }): ReactElement => {
    switch (excelTab) {
      case ExcelTabType.one:
        return (
          <div className="current-day__text h4" style={{ maxWidth: "unset" }}>
            Put your bins out on{" "}
            {binDay?.redServiceDay && getBinTakeoutDay(binDay?.redServiceDay)}{" "}
            nights for collection on
            <br />
            <span className="color-orange">{binDay?.redServiceDay || ""}</span>
          </div>
        );
      case ExcelTabType.twoA:
      case ExcelTabType.twoB:
      case ExcelTabType.twoC:
        return (
          <div className="current-day__text h4" style={{ maxWidth: "unset" }}>
            Your building’s bins may be collected on different days and by
            different trucks.
          </div>
        );
      default:
        return (
          <div className="current-day__text h4">
            Your current bin day is{" "}
            <span className="color-orange">{binDay?.redServiceDay || ""}</span>
          </div>
        );
    }
  };

  return (
    <div>
      <SeoHelmet
        title={
          binDay
            ? `${binDay.fullAddress} - Bin Collection Days`
            : "Bin Collection Days"
        }
        description="City of Canterbury Bankstown. Find your next red, yellow and green bin collection days."
      />
      <main className="main">
        <ResultPageHeader address={binDay?.fullAddress || ""} />

        {/* Bin Days */}
        <section className="bin-days section bg-gray">
          <div className="bin-days__container container">
            <div className="bin-days__grid bin-grid row">
              {/* Alert */}
              {showBanner && (
                <div className="bin-grid__alert-col bin-alert col-12">
                  <SharedBinAlertBanner />
                </div>
              )}
              {/* /Alert */}

              {/* Primary Column */}
              <div className="bin-grid__primary-col col-lg-3 col-sm-6">
                <div className="bin-grid__primary-item current-day">
                  <div className="current-day__icon">
                    <img
                      src={calendarIconSVG}
                      alt="Calendar Icon"
                      className="current-day__icon-img"
                    />
                  </div>
                  {binDay && <CalendarCardContent excelTab={binDay.tab} />}
                </div>
              </div>
              {/* /Primary Column */}
              <BinCardGroup
                binSchedule={binSchedule}
                showGreenBinDisclaimer={showGreenBinDisclaimer}
              />
            </div>
            {showDownloadButton && <DownloadCalendarBlock binDay={binDay} />}
          </div>
        </section>
        {/* /Bin Days */}
        <BinTipsBlock />

        <WastelessGenerationBlock />
      </main>
    </div>
  );
}
