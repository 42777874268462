import React, { ReactElement } from "react";
import alertIconSVG from "../assets/img/svg/graphic/alert.svg";

import alertIllustrationSharedPNG from "../assets/img/alert-illustration-shared.png";
import alertIllustrationSharedWEBP from "../assets/img/alert-illustration-shared.webp";

export default function SharedBinAlertBanner(): ReactElement {
  return (
    <div className="bin-alert__main">
      <header className="bin-alert__header">
        <img
          src={alertIconSVG}
          alt="Alert icon"
          className="bin-alert__header-icon"
        />
        <div className="bin-alert__header-title h4">
          Managing Bins in Your Apartment or Unit Block
        </div>
      </header>
      <div className="bin-alert__body">
        <div className="bin-alert__box">
          <div className="bin-alert__descr">
            <div className="bin-alert__text plaintext">
              <p>See below for the collection days for your shared:</p>
              <p>
                <strong className="color-accent">Red and yellow bins:</strong>{" "}
                Which may be put out by Council or a building caretaker.
              </p>
              <p>
                <strong className="color-accent">Green bins:</strong> Which may
                be put out by a resident or building caretaker the night before
                collection day.
              </p>
            </div>
          </div>
          <div className="bin-alert__pic">
            <picture>
              <source srcSet={alertIllustrationSharedWEBP} type="image/webp" />
              <img
                src={alertIllustrationSharedPNG}
                alt="Alert Illustration"
                className="bin-alert__pic-img"
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  );
}
