import React, { ReactElement, useCallback, useContext, useState } from "react";
import Downshift, { ControllerStateAndHelpers } from "downshift";
import { SpriteIcon } from "./graphics";
import { BinDay } from "../models";
import { useHistory } from "react-router-dom";
import { BinDayDatasetContext } from "../contexts";

const MIN_CHAR_INPUT_TO_START_SEARCH: number = 3;
const MAX_ITEMS_ON_DROPDOWN: number = 200;

export default function AddressSearchBox({
  formClassName,
}: {
  formClassName: string;
}): ReactElement {
  const history = useHistory();

  const { isLoaded, binDayDataset } = useContext(BinDayDatasetContext);
  const [items, setItems] = useState<BinDay[]>();

  const filterStreetAddress = useCallback(
    (inputValue: string, { isOpen }: ControllerStateAndHelpers<BinDay>) => {
      if (isLoaded) {
        if (isOpen && inputValue.length >= MIN_CHAR_INPUT_TO_START_SEARCH) {
          const cleanedInput = inputValue
            .split(",")[0]
            .replace(/[^\w\\/\-\s]/gi, "");

          let matchingItems: Array<BinDay> = [];
          const regExAddressWithAppartment = /\d+\s?\/\s?\d+/gi;
          if (regExAddressWithAppartment.test(cleanedInput)) {
            // --- #>1/32 Mac // matches: 1/32 Mac, 1/32-34 Mac, 1 / 32 Mac
            const tokens = cleanedInput.match(/(\d+\s?\/\s?\d?)?(\w)+/gi) || [];
            tokens[0] = tokens[0]
              .replace(/\s+/gi, "")
              .split("/")
              .join("\\s?/\\s?");
            const matchRegExp = new RegExp(
              `^${tokens.join("[\\s\\-\\d]+")}`,
              "i"
            );
            matchingItems = binDayDataset.filter((item) => {
              return matchRegExp.test(item.fullAddress);
            });
          } else {
            // --- #>32 mac // matches: 32 Mac, 32-34 Mac
            // --- #>34 Mac // matches: 34 Mac, 32-34 Mac
            const tokens = cleanedInput.match(/(\d+)?(\w)+/gi) || [];
            const matchRegExp = new RegExp(tokens.join("[\\s\\-\\d]+"), "i");
            matchingItems = binDayDataset.filter((item) => {
              return matchRegExp.test(item.address);
            });
          }

          if (matchingItems.length < 850) {
            setItems(matchingItems.slice(0, MAX_ITEMS_ON_DROPDOWN));
          }
        } else {
          setItems([]);
        }
      }
    },
    [isLoaded, binDayDataset]
  );

  return (
    <Downshift
      itemToString={(item) => (item ? `${item.fullAddress}` : "")}
      defaultIsOpen={false}
      onInputValueChange={filterStreetAddress}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        clearSelection,
        inputValue,
        isOpen,
        highlightedIndex,
        selectedItem,
      }) => {
        let alertMsg: string | null = null;
        if (
          isOpen &&
          inputValue &&
          inputValue.length > MIN_CHAR_INPUT_TO_START_SEARCH
        ) {
          if (isLoaded) {
            if (items && items.length === 0) {
              alertMsg =
                "Address not found in the Canterbury-Bankstown region.";
            } else {
              alertMsg = "";
            }
          } else {
            alertMsg = "Loading ...";
          }
        }

        return (
          <div style={{ width: "100%" }}>
            <form className={`search-street ${formClassName}`}>
              <label className="search-street__field field">
                <div className="field__input-wrap">
                  <input
                    {...getInputProps({
                      onFocus: clearSelection,
                    })}
                    type="search"
                    className="field__input field__input_with-icon field__input_with-btn input"
                    placeholder="Enter your street address ..."
                    required
                  />
                  <ul
                    {...getMenuProps()}
                    style={{
                      listStyle: "None",
                      position: "absolute",
                      zIndex: 100,
                    }}
                  >
                    {items &&
                      items.map((item, index) => (
                        <li
                          {...getItemProps({
                            key: `${item.fullAddress}`,
                            index,
                            item,
                            style: {
                              color: "#232a30",
                              padding: "15px",
                              backgroundColor:
                                highlightedIndex === index
                                  ? "#F8F8F8"
                                  : "white",
                              fontWeight:
                                selectedItem === item ? "bold" : "normal",
                            },
                          })}
                        >
                          {item.fullAddress}
                        </li>
                      ))}
                  </ul>
                  <SpriteIcon
                    iconName="location"
                    className="field__input-icon"
                  />
                </div>
              </label>
              <button
                className="search-street__search-btn button button_color_yellow button_size_medium button_shadow_default"
                onClick={(event) => {
                  event.preventDefault();
                  if (selectedItem) {
                    history.push({
                      pathname: "/result",
                      search: `address=${selectedItem.fullAddress}`,
                      state: {
                        lookupAddress: selectedItem,
                      },
                    });
                  }
                }}
              >
                Go
              </button>
            </form>
            {alertMsg && <div style={{ marginTop: "10px" }}>{alertMsg}</div>}
          </div>
        );
      }}
    </Downshift>
  );
}
