import { useLayoutEffect, useRef } from "react";

export default function useModalToggler(isModalOpen: boolean) {
  const modalRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (modalRef.current) {
      if (isModalOpen) {
        modalRef.current.classList.add("modal_active");
        document.body.classList.add("no-scroll");
      } else {
        modalRef.current.classList.remove("modal_active");
        document.body.classList.remove("no-scroll");
      }
    }
  }, [modalRef, isModalOpen]);

  return modalRef;
}
