import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import { useScrollToTop } from "../hooks";
import {
  BinDayCalendarModal,
  AddressSearchBox,
  ReportIssueModal,
  WastelessGenerationBlock,
} from "../components";
import { SpriteIcon, SeoHelmet } from "../components";

import mainHeroPNG from "../assets/img/main-hero.png";
import mainHeroWEBP from "../assets/img/main-hero.webp";
import sectionElementSVG from "../assets/img/svg/graphic/section-element.svg";
import calendarIconSVG from "../assets/img/svg/graphic/calendar.svg";
import reportIssueSVG from "../assets/img/svg/graphic/issue.svg";
import moreInfoSVG from "../assets/img/svg/graphic/info.svg";
import redBinSVG from "../assets/img/bin-types/red.svg";
import yellowBinSVG from "../assets/img/bin-types/yellow.svg";
import binTypesIllustrationPNG from "../assets/img/bin-types-photo.png";
import binTypesIllustrationWEBP from "../assets/img/bin-types-photo.webp";

enum HomePageModalType {
  binDayCalendar,
  reportIssue,
  share,
}

export default function HomePage(): ReactElement {
  useScrollToTop();

  const [
    currentOpenModalType,
    setCurrentOpenModalType,
  ] = useState<HomePageModalType | null>(null);

  return (
    <div>
      <SeoHelmet
        title="Bin Day Finder - City of Canterbury Bankstown"
        description="Find out what days your red, yellow and green bins are collected and download your bin day calendar."
      />
      <main className="main">
        {/* Page Hero */}
        <section className="main-hero">
          <div className="main-hero__container container">
            <div className="main-hero__box">
              <div className="main-hero__descr">
                <h1 className="main-hero__title h1">
                  <span className="color-orange">Bin Day</span> Finder
                </h1>
                <h2 className="main-hero__subtitle">
                  Find out what day your bins are collected
                </h2>
                <AddressSearchBox formClassName="main-hero__search" />
              </div>
              <div className="main-hero__illustration">
                <picture>
                  <source srcSet={mainHeroWEBP} type="image/webp" />
                  <img
                    src={mainHeroPNG}
                    alt="Main Hero Illustration"
                    className="main-hero__illustration-img"
                  />
                </picture>
              </div>
              <div className="main-hero__arrow" aria-hidden="true">
                <SpriteIcon
                  iconName="curved-arrow"
                  className="main-hero__arrow-img"
                />
              </div>
              <div
                className="main-hero__element section-element"
                aria-hidden="true"
              >
                <img
                  src={sectionElementSVG}
                  alt="Section element"
                  className="section-element__img"
                />
              </div>
            </div>
          </div>
        </section>
        {/* /Page Hero */}
        {/* Sections */}
        <section className="sections section bg-gray">
          <div className="sections__container container">
            <div className="sections__set sections-set row">
              {/* Section Card */}
              <div className="sections-set__col col-lg-4">
                <div className="sections-set__card section-card">
                  <div className="section-card__icon">
                    <img
                      src={calendarIconSVG}
                      alt="Bin day calendar Icon"
                      className="section-card__icon-img"
                    />
                  </div>
                  <div className="section-card__descr">
                    <h4 className="section-card__title h4">Bin day calendar</h4>
                    <div className="section-card__text">
                      Download a handy printable bin&nbsp;day&nbsp;calendar for
                      your address.
                    </div>
                    <div className="section-card__action">
                      <button
                        className="section-card__action-btn button button_type_ghost button_size_medium js-modal-opener"
                        onClick={() =>
                          setCurrentOpenModalType(
                            HomePageModalType.binDayCalendar
                          )
                        }
                      >
                        Download now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Section Card */}
              {/* Section Card */}
              <div className="sections-set__col col-lg-4">
                <div className="sections-set__card section-card">
                  <div className="section-card__icon">
                    <img
                      src={reportIssueSVG}
                      alt="Report an issue Icon"
                      className="section-card__icon-img"
                    />
                  </div>
                  <div className="section-card__descr">
                    <h4 className="section-card__title h4">Report an issue</h4>
                    <div className="section-card__text">
                      Let us know if your bins weren't collected as scheduled.
                    </div>
                    <div className="section-card__action">
                      <button
                        className="section-card__action-btn button button_type_ghost button_size_medium js-modal-opener"
                        onClick={() =>
                          setCurrentOpenModalType(HomePageModalType.reportIssue)
                        }
                      >
                        Get assistance
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Section Card */}
              {/* Section Card */}
              <div className="sections-set__col col-lg-4">
                <div className="sections-set__card section-card">
                  <div className="section-card__icon">
                    <img
                      src={moreInfoSVG}
                      alt="More information Icon"
                      className="section-card__icon-img"
                    />
                  </div>
                  <div className="section-card__descr">
                    <h4 className="section-card__title h4">More information</h4>
                    <div className="section-card__text">
                      Damaged or missed bins, getting extra&nbsp;bins, household
                      clean ups, and&nbsp;more...
                    </div>
                    <div className="section-card__action">
                      <Link
                        to="/about"
                        className="section-card__action-btn button button_type_ghost button_size_medium"
                      >
                        Find out more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Section Card */}
            </div>
          </div>
        </section>
        {/* /Sections */}
        {/* Bin Types */}
        <section className="bin-types">
          <div className="bin-types__container container">
            <div className="bin-types__box">
              <div className="bin-types__descr">
                <div className="bin-types__arrow" aria-hidden="true">
                  <SpriteIcon
                    iconName="curved-arrow"
                    className="bin-types__arrow-img"
                  />
                </div>
                <h2 className="bin-types__title h2">What Bin When?</h2>
                <ul className="bin-types__list">
                  <li className="bin-types__list-item">
                    <img
                      src={redBinSVG}
                      alt="Red Lidded Icon"
                      className="bin-types__list-icon"
                    />
                    <div className="bin-types__list-text">
                      Your <strong>red</strong> lidded rubbish bin is emptied
                      weekly.
                    </div>
                  </li>
                  <li className="bin-types__list-item">
                    <img
                      src={yellowBinSVG}
                      alt="Yellow and Green Lidded Icon"
                      className="bin-types__list-icon"
                    />
                    <div className="bin-types__list-text">
                      <strong>Yellow</strong> lidded recycling and green lidded
                      garden waste bins are emptied on alternating weeks.
                    </div>
                  </li>
                </ul>
              </div>
              <div className="bin-types__photo">
                <div className="bin-types__photo-inner">
                  <picture>
                    <source
                      srcSet={binTypesIllustrationWEBP}
                      type="image/webp"
                    />
                    <img
                      src={binTypesIllustrationPNG}
                      alt="Bin Types Illustration"
                      className="bin-types__photo-img"
                    />
                  </picture>
                </div>
              </div>
              <div
                className="bin-types__element section-element"
                aria-hidden="true"
              >
                <img
                  src={sectionElementSVG}
                  alt="Section element"
                  className="section-element__img"
                />
              </div>
              <div className="bin-types__info">
                <SpriteIcon
                  iconName="info-circle"
                  className="bin-types__arrow-img"
                />
              </div>
            </div>
          </div>
        </section>
        {/* /Bin Types */}
        <WastelessGenerationBlock />
      </main>
      {/* Modals */}
      <div className="modals">
        <BinDayCalendarModal
          isModalOpen={
            currentOpenModalType === HomePageModalType.binDayCalendar
          }
          onModalClose={() => setCurrentOpenModalType(null)}
        />

        <ReportIssueModal
          isModalOpen={currentOpenModalType === HomePageModalType.reportIssue}
          onModalClose={() => setCurrentOpenModalType(null)}
        />
      </div>
      {/* Modals */}
    </div>
  );
}
