import React, { CSSProperties, ReactElement } from "react";
import redBinIconSVG from "../assets/img/svg/graphic/bin_red.svg";
import yellowBinIconSVG from "../assets/img/svg/graphic/bin_yellow.svg";
import greenBinIconSVG from "../assets/img/svg/graphic/bin_green.svg";
import { BinType } from "../models";
import { formatDisplayDate } from "../utils";

const cardAttributes = {
  [BinType.red]: {
    name: "Red Bin",
    colorCode: "#F0533E",
    iconSVG: redBinIconSVG,
  },
  [BinType.yellow]: {
    name: "Yellow Bin",
    colorCode: "#FFCB05",
    iconSVG: yellowBinIconSVG,
  },
  [BinType.green]: {
    name: "Green Bin",
    colorCode: "#8DC63F",
    iconSVG: greenBinIconSVG,
  },
};

interface BinCardProps {
  binType: BinType;
  binDates: Array<Date | null>;
  title: string;
  showGreenBinDisclaimer?: boolean;
}

export default function BinCard({
  binType,
  binDates,
  title,
  showGreenBinDisclaimer = false,
}: BinCardProps): ReactElement {
  const { name, colorCode, iconSVG } = cardAttributes[binType];

  return (
    <div className="bin-grid__col col-lg-3 col-sm-6" key={name}>
      <div
        className="bin-grid__item bin-card"
        style={{ "--color": colorCode } as CSSProperties}
      >
        <h3 className="bin-card__title h4">{title}</h3>
        <div className="bin-card__icon">
          <img
            src={iconSVG}
            alt={`${name} Icon`}
            className="bin-card__icon-img"
          />
        </div>
        <div className="bin-card__date">
          {binDates &&
            binDates.map((date, idx) => (
              <div key={idx}>
                {date && (
                  <>
                    {formatDisplayDate(date)}
                    <br />
                  </>
                )}
              </div>
            ))}
        </div>
        {showGreenBinDisclaimer && (
          <div className="bin-card__note">(If your building has them)</div>
        )}
      </div>
    </div>
  );
}
