import React, { ReactElement } from "react";
import { Link } from "react-router-dom";

import binTipsIconSVG from "../assets/img/svg/graphic/bin-tips.svg";
import womanImagePNG from "../assets/img/woman.png";
import womanImageWEBP from "../assets/img/woman.webp";
import { SpriteIcon } from "./graphics";

export default function BinTipsBlock(): ReactElement {
  return (
    <section className="bin-tips">
      <div className="bin-tips__container container">
        <div className="bin-tips__box">
          <div className="bin-tips__icon">
            <img
              src={binTipsIconSVG}
              alt="Bin Tips Icon"
              className="bin-tips__icon-img"
            />
          </div>
          <div className="bin-tips__descr">
            <h2 className="bin-tips__title h2">Bin tips</h2>
            <div className="bin-tips__text subtitle">
              Try to put your bin on the kerb as late as possible the night
              before your scheduled collection,{" "}
              <strong>
                with the wheels towards the kerb and with a 30cm gap between the
                bins.
              </strong>
            </div>
            <div className="bin-tips__actions">
              <Link
                to="/about"
                className="bin-tips__actions-btn button button_shadow_default"
              >
                More bin tips
              </Link>
            </div>
          </div>
          <div className="bin-tips__photo">
            <div className="bin-tips__photo-inner">
              <picture>
                <source srcSet={womanImageWEBP} type="image/webp" />
                <img
                  src={womanImagePNG}
                  alt="Woman"
                  className="bin-tips__photo-img"
                />
              </picture>
            </div>
          </div>
          <div className="bin-tips__arrow" aria-hidden="true">
            <SpriteIcon
              iconName="curved-arrow"
              className="bin-tips__arrow-img"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
