import React, { ReactElement } from "react";

import { SpriteIcon } from "../graphics";
import orangeIssueIconSVG from "../../assets/img/svg/graphic/issue_orange.svg";
import { useModalToggler } from "../../hooks";

interface ReportIssueModalProps {
  isModalOpen: boolean;
  onModalClose: () => void;
}

export default function ReportIssueModal({
  isModalOpen,
  onModalClose,
}: ReportIssueModalProps): ReactElement {
  const modalRef = useModalToggler(isModalOpen);

  return (
    <div
      ref={modalRef}
      className="issue-modal modal js-modal"
      data-modal-id="report-issue"
    >
      <div className="modal__box">
        <div className="modal__content default-modal issue-modal__main">
          <button
            className="default-modal__close js-modal-close"
            type="button"
            onClick={onModalClose}
          >
            <SpriteIcon iconName="x" className="default-modal__close-icon" />
          </button>
          <div className="issue-modal__box default-modal__box align-center">
            <div className="default-modal__icon">
              <img
                src={orangeIssueIconSVG}
                alt="Issue Icon"
                className="default-modal__icon-img"
              />
            </div>
            <h3 className="default-modal__title h4">
              Sorry there's been an issue
            </h3>
            <div className="default-modal__text">
              Let us know if your bin hasn't been collected as scheduled by
              calling
              <a href="tel:97079000"> 9707&nbsp;9000 </a>
              or submitting an online report.
            </div>
            <div className="default-modal__actions">
              <a
                href="https://reportit.cbcity.nsw.gov.au/"
                className="issue-modal__submit default-modal__actions-btn button button_size_large button_shadow_default js-modal-close"
              >
                Submit online request
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
