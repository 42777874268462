import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { AboutPage, HomePage, ResultPage } from "./pages";
import { HelmetProvider } from "react-helmet-async";
import { BinDayDatasetProvider } from "./contexts";

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <BinDayDatasetProvider>
          <Header />
          <Switch>
            <Route path="/" component={HomePage} exact />
            <Route path="/result" component={ResultPage} exact />
            <Route path="/about" component={AboutPage} exact />
          </Switch>
          <Footer />
        </BinDayDatasetProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
