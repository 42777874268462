import {
  BinCollectionZone,
  BinDay,
  BinSchedule,
  BinServiceDay,
  ExcelTabType,
} from "../models";

const FORTNIGHT_CYCLE_REFERENCE_DATE = new Date(2021, 2, 7);

const ZERO_INDEXED_DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const addDaysToDate = (jsDate: Date, numberOfDays: number): Date => {
  const jsDateClone = new Date(+jsDate);
  jsDateClone.setDate(jsDate.getDate() + numberOfDays);
  return jsDateClone;
};

const getLookupDate = (): Date => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
};

/* Returns Date in the future */
const getBinDateFromServiceDay = (serviceDay: BinServiceDay): Date => {
  const today = getLookupDate();
  const diffTodayToServiceDay =
    today.getDay() - ZERO_INDEXED_DAYS.indexOf(serviceDay);
  if (diffTodayToServiceDay > 0) {
    today.setDate(today.getDate() - diffTodayToServiceDay);
  } else if (diffTodayToServiceDay < 0) {
    today.setDate(today.getDate() + Math.abs(diffTodayToServiceDay));
  }
  return today;
};

/*
Date Evaluation rules for 2-week cycle --
[red,<red2>|yellow|green]
-- TAB1 --
- zone A
week1: red | red      | NEXT red
week2: red | NEXT red | red
- zone B
week1: red | NEXT red | red
week2: red | red      | NEXT red
*/
const getTab1BinSchedule = (
  binDay: BinDay,
  isInitialWeek = false
): BinSchedule => {
  const { redServiceDay, zone } = binDay;

  const redBinDate = getBinDateFromServiceDay(redServiceDay);
  let yellowBinDate: Date | null;
  let greenBinDate: Date | null;

  if (zone === BinCollectionZone.zoneA) {
    if (isInitialWeek) {
      yellowBinDate = redBinDate;
      greenBinDate = addDaysToDate(redBinDate, 7);
    } else {
      yellowBinDate = addDaysToDate(redBinDate, 7);
      greenBinDate = redBinDate;
    }
  } else {
    if (isInitialWeek) {
      yellowBinDate = addDaysToDate(redBinDate, 7);
      greenBinDate = redBinDate;
    } else {
      yellowBinDate = redBinDate;
      greenBinDate = addDaysToDate(redBinDate, 7);
    }
  }

  return {
    redBinDate,
    redBinDate2: null,
    redBinDate3: null,
    redBinDate4: null,
    yellowBinDate,
    yellowBinDate2: null,
    yellowBinDate3: null,
    greenBinDate,
  };
};

/*
-- TAB2A --
- zone A
week1: red, <red2> | red, <red2> | NEXT green
week2: red, <red2> | red, <red2> | green

- zone B
week1: red, <red2> | red, <red2> | green
week2: red, <red2> | red, <red2> | NEXT green
*/
const getTab2aBinSchedule = (
  binDay: BinDay,
  isInitialWeek = false
): BinSchedule => {
  const { redServiceDay, redServiceDay2, greenServiceDay, zone } = binDay;

  const redBinDate = getBinDateFromServiceDay(redServiceDay);
  const redBinDate2 = redServiceDay2
    ? getBinDateFromServiceDay(redServiceDay2)
    : null;
  let greenBinDate = getBinDateFromServiceDay(greenServiceDay);

  if (zone === BinCollectionZone.zoneA) {
    if (isInitialWeek) {
      greenBinDate = addDaysToDate(greenBinDate, 7);
    }
  } else {
    if (!isInitialWeek) {
      greenBinDate = addDaysToDate(greenBinDate, 7);
    }
  }

  return {
    redBinDate,
    redBinDate2,
    redBinDate3: null,
    redBinDate4: null,
    yellowBinDate: redBinDate,
    yellowBinDate2: redBinDate2,
    yellowBinDate3: null,
    greenBinDate,
  };
};

/*
-- TAB2B --
- zone A
week 1: red, <red2> | green       | NEXT green
week 2: red, <red2> | NEXT green  | green

- zone B
week1: red, <red2> | NEXT green | green
week2: red, <red2> | green      | NEXT green
*/
const getTab2bBinSchedule = (
  binDay: BinDay,
  isInitialWeek = false
): BinSchedule => {
  const { redServiceDay, redServiceDay2, greenServiceDay, zone } = binDay;

  const redBinDate = getBinDateFromServiceDay(redServiceDay);
  const redBinDate2 = redServiceDay2
    ? getBinDateFromServiceDay(redServiceDay2)
    : null;
  let yellowBinDate: Date | null;
  let greenBinDate = getBinDateFromServiceDay(greenServiceDay);

  if (zone === BinCollectionZone.zoneA) {
    if (isInitialWeek) {
      yellowBinDate = greenBinDate;
      greenBinDate = addDaysToDate(greenBinDate, 7);
    } else {
      yellowBinDate = addDaysToDate(greenBinDate, 7);
    }
  } else {
    if (isInitialWeek) {
      yellowBinDate = addDaysToDate(greenBinDate, 7);
    } else {
      yellowBinDate = greenBinDate;
      greenBinDate = addDaysToDate(greenBinDate, 7);
    }
  }

  return {
    redBinDate,
    redBinDate2,
    redBinDate3: null,
    redBinDate4: null,
    yellowBinDate,
    yellowBinDate2: null,
    yellowBinDate3: null,
    greenBinDate,
  };
};

/*
-- TAB2C --
- zone A - yellowZone A
week1: red, <red2> | yellow       | NEXT green
week2: red, <red2> | NEXT yellow  | green
- zone A - yellowZone B
week1: red, <red2> | NEXT yellow  | NEXT green
week2: red, <red2> | yellow       | green

- zone B - yellowZone A
week1: red, <red2> | yellow       | green
week2: red, <red2> | NEXT yellow  | NEXT green
- zone A - yellowZone B
week1: red, <red2> | NEXT yellow  | green
week2: red, <red2> | yellow       | NEXT green
*/
const getTab2cBinSchedule = (
  binDay: BinDay,
  isInitialWeek = false
): BinSchedule => {
  const {
    redServiceDay,
    redServiceDay2,
    greenServiceDay,
    zone,
    yellowServiceDay,
    yellowZone,
  } = binDay;

  const redBinDate = getBinDateFromServiceDay(redServiceDay);
  const redBinDate2 = redServiceDay2
    ? getBinDateFromServiceDay(redServiceDay2)
    : null;
  let yellowBinDate = getBinDateFromServiceDay(yellowServiceDay);
  let greenBinDate = getBinDateFromServiceDay(greenServiceDay);

  if (zone === BinCollectionZone.zoneA) {
    if (isInitialWeek) {
      if (yellowZone === BinCollectionZone.zoneB) {
        yellowBinDate = addDaysToDate(yellowBinDate, 7);
      }
      greenBinDate = addDaysToDate(greenBinDate, 7);
    } else {
      if (yellowZone === BinCollectionZone.zoneA) {
        yellowBinDate = addDaysToDate(yellowBinDate, 7);
      }
    }
  } else {
    if (isInitialWeek) {
      if (yellowZone === BinCollectionZone.zoneB) {
        yellowBinDate = addDaysToDate(yellowBinDate, 7);
      }
    } else {
      if (yellowZone === BinCollectionZone.zoneA) {
        yellowBinDate = addDaysToDate(yellowBinDate, 7);
      }
      greenBinDate = addDaysToDate(greenBinDate, 7);
    }
  }

  return {
    redBinDate,
    redBinDate2,
    redBinDate3: null,
    redBinDate4: null,
    yellowBinDate,
    yellowBinDate2: null,
    yellowBinDate3: null,
    greenBinDate,
  };
};

/*
-- TAB 3 --
- zone A
week1: red | red | NEXT red
week2: red | red | red
- zone B
week 1: red | red | red
week 2: red | red | NEXT red
*/
const getTab3BinSchedule = (
  binDay: BinDay,
  isInitialWeek = false
): BinSchedule => {
  const { redServiceDay, zone } = binDay;

  const redBinDate = getBinDateFromServiceDay(redServiceDay);
  let greenBinDate: Date | null;

  if (zone === BinCollectionZone.zoneA) {
    if (isInitialWeek) {
      greenBinDate = addDaysToDate(redBinDate, 7);
    } else {
      greenBinDate = redBinDate;
    }
  } else {
    if (isInitialWeek) {
      greenBinDate = redBinDate;
    } else {
      greenBinDate = addDaysToDate(redBinDate, 7);
    }
  }

  return {
    redBinDate,
    redBinDate2: null,
    redBinDate3: null,
    redBinDate4: null,
    yellowBinDate: redBinDate,
    yellowBinDate2: null,
    yellowBinDate3: null,
    greenBinDate,
  };
};

/*
-- TAB4 --
- zone A
week1: red, <red2> | red, <red2> | NEXT green
week2: red, <red2> | red, <red2> | green

- zone B
N/A
*/
const getTab4BinSchedule = (
  binDay: BinDay,
  isInitialWeek = false
): BinSchedule => {
  const { redServiceDay, redServiceDay2, greenServiceDay, zone } = binDay;

  const redBinDate = getBinDateFromServiceDay(redServiceDay);
  const redBinDate2 = redServiceDay2
    ? getBinDateFromServiceDay(redServiceDay2)
    : null;
  let greenBinDate = getBinDateFromServiceDay(greenServiceDay);

  if (zone === BinCollectionZone.zoneA) {
    if (isInitialWeek) {
      greenBinDate = addDaysToDate(greenBinDate, 7);
    }
  } else {
    if (!isInitialWeek) {
      greenBinDate = addDaysToDate(greenBinDate, 7);
    }
  }

  return {
    redBinDate,
    redBinDate2,
    redBinDate3: null,
    redBinDate4: null,
    yellowBinDate: redBinDate,
    yellowBinDate2: redBinDate2,
    yellowBinDate3: null,
    greenBinDate,
  };
};

/*
-- TAB5 --
(similar as tab 2c, but with red3)
- zone A - yellowZone A
week1: red, <red2>, <red3> | yellow       | NEXT green
week2: red, <red2>, <red3> | NEXT yellow  | green
- zone A - yellowZone B
week1: red, <red2>, <red3> | NEXT yellow  | NEXT green
week2: red, <red2>, <red3> | yellow       | green

- zone B - yellowZone A
week1: red, <red2>, <red3> | yellow       | green
week2: red, <red2>, <red3> | NEXT yellow  | NEXT green
- zone A - yellowZone B
week1: red, <red2>, <red3> | NEXT yellow  | green
week2: red, <red2>, <red3> | yellow       | NEXT green
*/
const getTab5BinSchedule = (
  binDay: BinDay,
  isInitialWeek = false
): BinSchedule => {
  const {
    redServiceDay,
    redServiceDay2,
    redServiceDay3,
    greenServiceDay,
    zone,
    yellowServiceDay,
    yellowZone,
  } = binDay;

  const redBinDate = getBinDateFromServiceDay(redServiceDay);
  const redBinDate2 = redServiceDay2
    ? getBinDateFromServiceDay(redServiceDay2)
    : null;
  const redBinDate3 = redServiceDay3
    ? getBinDateFromServiceDay(redServiceDay3)
    : null;
  let yellowBinDate = getBinDateFromServiceDay(yellowServiceDay);
  let greenBinDate = getBinDateFromServiceDay(greenServiceDay);

  if (zone === BinCollectionZone.zoneA) {
    if (isInitialWeek) {
      if (yellowZone === BinCollectionZone.zoneB) {
        yellowBinDate = addDaysToDate(yellowBinDate, 7);
      }
      greenBinDate = addDaysToDate(greenBinDate, 7);
    } else {
      if (yellowZone === BinCollectionZone.zoneA) {
        yellowBinDate = addDaysToDate(yellowBinDate, 7);
      }
    }
  } else {
    if (isInitialWeek) {
      if (yellowZone === BinCollectionZone.zoneB) {
        yellowBinDate = addDaysToDate(yellowBinDate, 7);
      }
    } else {
      if (yellowZone === BinCollectionZone.zoneA) {
        yellowBinDate = addDaysToDate(yellowBinDate, 7);
      }
      greenBinDate = addDaysToDate(greenBinDate, 7);
    }
  }

  return {
    redBinDate,
    redBinDate2,
    redBinDate3,
    redBinDate4: null,
    yellowBinDate,
    yellowBinDate2: null,
    yellowBinDate3: null,
    greenBinDate,
  };
};

/*
-- TAB6 --
(similar as tab 2c, but with red3 and red4)
*/
const getTab6BinSchedule = (
  binDay: BinDay,
  isInitialWeek = false
): BinSchedule => {
  const {
    redServiceDay,
    redServiceDay2,
    redServiceDay3,
    redServiceDay4,
    greenServiceDay,
    zone,
    yellowServiceDay,
    yellowZone,
  } = binDay;

  const redBinDate = getBinDateFromServiceDay(redServiceDay);
  const redBinDate2 = redServiceDay2
    ? getBinDateFromServiceDay(redServiceDay2)
    : null;
  const redBinDate3 = redServiceDay3
    ? getBinDateFromServiceDay(redServiceDay3)
    : null;
  const redBinDate4 = redServiceDay4
    ? getBinDateFromServiceDay(redServiceDay4)
    : null;
  let yellowBinDate = getBinDateFromServiceDay(redServiceDay);
  let yellowBinDate2 = getBinDateFromServiceDay(yellowServiceDay);
  let yellowBinDate3 = getBinDateFromServiceDay(greenServiceDay);
  let greenBinDate = getBinDateFromServiceDay(greenServiceDay);

  if (zone === BinCollectionZone.zoneA) {
    if (isInitialWeek) {
      if (yellowZone === BinCollectionZone.zoneB) {
        yellowBinDate = addDaysToDate(yellowBinDate, 7);
      }
      greenBinDate = addDaysToDate(greenBinDate, 7);
    } else {
      if (yellowZone === BinCollectionZone.zoneA) {
        yellowBinDate = addDaysToDate(yellowBinDate, 7);
      }
    }
  } else {
    if (isInitialWeek) {
      if (yellowZone === BinCollectionZone.zoneB) {
        yellowBinDate = addDaysToDate(yellowBinDate, 7);
      }
    } else {
      if (yellowZone === BinCollectionZone.zoneA) {
        yellowBinDate = addDaysToDate(yellowBinDate, 7);
      }
      greenBinDate = addDaysToDate(greenBinDate, 7);
    }
  }

  return {
    redBinDate,
    redBinDate2,
    redBinDate3,
    redBinDate4,
    yellowBinDate,
    yellowBinDate2,
    yellowBinDate3,
    greenBinDate,
  };
};

const dateEvaluator: Readonly<
  {
    [key in ExcelTabType]: (
      binDay: BinDay,
      isInitialWeek?: boolean
    ) => BinSchedule;
  }
> = Object.freeze({
  [ExcelTabType.one]: getTab1BinSchedule,
  [ExcelTabType.twoA]: getTab2aBinSchedule,
  [ExcelTabType.twoB]: getTab2bBinSchedule,
  [ExcelTabType.twoC]: getTab2cBinSchedule,
  [ExcelTabType.three]: getTab3BinSchedule,
  [ExcelTabType.four]: getTab4BinSchedule,
  [ExcelTabType.five]: getTab5BinSchedule,
  [ExcelTabType.six]: getTab6BinSchedule,
});

export default function getBinServiceSchedule(
  binDay: BinDay | undefined
): BinSchedule {
  if (binDay) {
    const today = getLookupDate();
    const timeDiffInMs = +today - +FORTNIGHT_CYCLE_REFERENCE_DATE;
    const timeDiffInDays = Math.ceil(timeDiffInMs / (1000 * 60 * 60 * 24));
    const quotient = Math.floor(timeDiffInDays / 7);
    const isCurrentWeekInitialWeekOfTwoWeekCycle = quotient % 2 === 0;

    return dateEvaluator[binDay.tab](
      binDay,
      isCurrentWeekInitialWeekOfTwoWeekCycle
    );
  }

  return {
    redBinDate: null,
    redBinDate2: null,
    redBinDate3: null,
    redBinDate4: null,
    yellowBinDate: null,
    yellowBinDate2: null,
    yellowBinDate3: null,
    greenBinDate: null,
  };
}
